.row-prd-xxl-5 {
  @media screen and (min-width: 1600px) {
    .box-product-sample-s1 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}

.box-product-sample-s1 {
  margin-top: 15px;
  margin-bottom: 15px;

  .box-link {
    position: relative;
    display: inline-block;
    padding: 10px 10px 16px 10px;
    width: 100%;
    color: #3e3e3e;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.15);
    background-color: #ffffff;
    text-decoration: none !important;

    .bubble {
      position: absolute;
      padding: 4px 9px 3px 9px;
      min-width: 60px;
      top: 18px;
      left: 18px;
      color: #ffffff;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: center;
      border-radius: 25px;
      background-color: $cprimary;
      z-index: 10;
    }

    .box-image {
      display: block;
      border-radius: 8px;
      background-color: #efefef;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .box-descr {
      display: block;
      padding: 14px 0 8px 0;

      .name {
        margin-bottom: 4px;
        min-height: 40px;
        font-size: 1.25rem;
        text-align: center;
        font-weight: 700;
        line-height: 1.0;
      }

      .descr {
        position: relative;
        display: block;
        padding-top: 1px;
        color: #67686e;
        font-size: 0.95rem;
        text-align: center;
        line-height: 1.25;
        height: 61px;
        overflow: hidden;

        p,h1,h2,h3,h4,h5,h6,ol,ul,li,span,strong {
          color: #67686e !important;
          font-size: 0.85rem;
        }
        ul, ol {
          padding-left: 12px;
        }
      }

      .price {
        margin-top: 10px;
        color: $cprimary;
        font-size: 1.20rem;
        font-weight: 700;
      }
    }
  }

  a.box-link {
    cursor: pointer;
    @include transition(250ms);

    &:hover {
      opacity: 0.85;
      border-color: $cprimary;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1299px) {
    .box-link {
      .box-discount {
        font-size: 0.75rem;
      }
    }
  }
  @media screen and (max-width: 575px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
