.our-customers-section {
  position: relative;
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #ffffff;

  .title {
    color: #000000;
    text-align: center;
    font-size: 2.1rem;
    font-weight: 900;
    letter-spacing: 3px;
  }

  .box-carousel {
    position: relative;
    margin-top: 20px;

    .swiper {
      .swiper-slide {
        padding-bottom: 35px;
      }
      .swiper-pagination {
        bottom: 0;
      }
    }

    .box-brand {
      position: relative;
      @include flex-center-xy();
      padding: 10px;
      flex-wrap: 0 0 100%;
      max-width: 100%;
      height: 190px;
      border-radius: 14px;
      background-color: #ffffff;

      .image {
        display: inline-block;
        width: 100%;
        text-align: center;

        img {
          max-width: 85%;
          max-height: 148px;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    padding-top: 26px;
    padding-bottom: 26px;
  }
}
