#footer{
  position: relative;

  .footer-section{
    position: relative;
    padding: 8px 0 20px 0;
    color: #ffffff;
    background-color: #262728;
    z-index: 85;

    a{
      display: inline-block;
      color: #ffffff;
    }

    .col-footer{
      padding-top: 14px;
      padding-bottom: 14px;

      h5{
        margin-bottom: 10px;
        color: #9c9c9c;
        font-size: 1.05rem;
        font-weight: 500;
        letter-spacing: 1px;
      }

      .link-footer{
        font-size: 1.05rem;
        font-weight: 400;

        &.txt-sm {
          font-size: 0.91rem;
        }
      }

      .p-md {
        font-size: 0.95rem;
        line-height: 1.4;
      }

      .copy-text{
        color: #9c9c9c;
        font-size: 0.90rem;
        font-weight: 400;
      }
    }

    .col-copyright{
      text-align: right;

      .p-sm{
        color: #9c9c9c;
        font-size: 0.86rem;
        line-height: 1.35;

        &.p-icon{
          position: relative;
          padding-right: 32px;

          &:after{
            position: absolute;
            top: 2px;
            right: 0;
            color: #9c9c9c;
            font-family: "Font Awesome 5 Pro";
            font-size: 1.2rem;
            font-weight: 300;
            line-height: 1;
          }

          &.i-letter{
            &:after{
              content: "\f0e0";
            }
          }
          &.i-phone{
            &:after{
              content: "\f095";
            }
          }
          &.i-map{
            &:after{
              content: "\f3c5";
            }
          }
        }
      }

      .btn-network{
        position: relative;
        display: inline-block;
        margin: 0 2px;

        span{
          @include flex-center-xy();
          width: 38px;
          flex: 0 0 38px;
          max-width: 38px;
          height: 38px;
          color: #000000;
          font-size: 1.2rem;
          border-radius: 50%;
          background: #ffffff;
        }

        &:hover{
          opacity: 0.8;
        }
      }
    }

    @media screen and (max-width: 991px){
      .col-footer{
        padding-top: 6px;
        padding-bottom: 6px;
        text-align: center;

        h5{
          margin-bottom: 2px;
          font-size: 1.05rem;
        }
      }

      .col-copyright{
        .p-sm{
          &.p-icon{
            padding-right: 0;

            &:after{
              display: none;
            }
          }
        }
      }
    }
    @media screen and (max-width: 575px){
      .col-footer{
        font-size: 1.0rem;
      }
    }
  }
}
