#home-page {

	.banner-section {
		position: relative;
		width: 100%;

		.swiper {
			.swiper-slide {
				.box {
					display: block;

					img {
						width: 100%;
					}
				}
			}

			.swiper-button-prev, .swiper-button-next {
				width: 60px;
				height: 60px;
				color: #ffffff;
				border: 2px solid #ffffff;
				border-radius: 50%;
				background-color: transparent;
				@include transition(150ms);
				opacity: 1;

				&:after {
					font-family: "Font Awesome 5 Pro";
					font-weight: 500;
					font-size: 1.9rem;
				}

				&:hover {
					background-color: rgba(255,255,255,0.10);
				}
			}

			.swiper-button-prev {
				left: 20px;
				padding-right: 2px;

				&:after {
					content: "\f053";
				}
			}

			.swiper-button-next {
				right: 20px;
				padding-left: 3px;

				&:after {
					content: "\f054";
				}
			}

			.swiper-pagination  {
				.swiper-pagination-bullet-active {
					border: 2px solid #ffffff;
					background-color: $cprimary;
					width: 12px;
					height: 12px;
				}
			}
		}

		.desktop {}
		.mobile {
			display: none;
		}


		@media screen and (min-width: 768px) {
			.swiper {
				.swiper-pagination {
					display: none !important;
				}
			}
		}

		@media screen and (max-width: 767px) {
			.desktop {
				display: none;
			}
			.mobile {
				display: block;
			}

			.swiper {
				.swiper-button-prev, .swiper-button-next {
					width: 52px;
					height: 52px;
					display: none;

					&:after {
						font-size: 1.55rem;
					}
				}

				.swiper-button-prev {
					left: 14px;
				}
				.swiper-button-next {
					right: 14px;
				}
			}
		}
	}

	.video-section {
		padding-top: 45px;
		padding-bottom: 45px;
		background-color: #1f2327;

		.col-title {
			margin-bottom: 18px;
			text-align: center;

			.title {
				color: #ffffff;
				font-size: 2.1rem;
  			font-weight: 900;
				letter-spacing: 3px;
			}
		}

		.col-video {
			video {
				width: 100%;
				height: 400px;
				object-fit: cover;
				border: 3px solid $cprimary;
				border-radius: 3px;
				background-color: #000000;
			}
		}

		@media screen and (max-width: 575px) {
			padding-top: 20px;
  		padding-bottom: 20px;
		}
	}

	.values-section {
		padding-top: 45px;
		padding-bottom: 45px;
		background-color: #f6f6f6;

		.col-title {
			margin-bottom: 18px;
			text-align: center;

			.title {
				font-size: 2.1rem;
  			font-weight: 900;
				letter-spacing: 3px;
			}
		}

		.col-value {
			.box-icon {
				position: relative;
				display: inline-block;
				width: 100%;
				text-align: center;

				.circle {
					@include flex-center-xy();
					margin: 0 auto;
					flex: 0 0 150px;
					max-width: 150px;
					height: 150px;
					overflow: hidden;
					color: #ffffff;
					border-radius: 50%;
					background: $cprimary;

					span {
						display: inline-block;
						width: 100%;
						text-align: center;
					}

					img {
						position: relative;
						max-width: 68px;
					}
				}
			}

			.title {
				margin-top: 12px;
				color: $cprimary;
				font-size: 1.40rem;
				font-weight: 800;
				text-align: center;
			}

			@media screen and (min-width: 992px) {
				&:nth-child(1) {
					.box-icon {
						.circle {
							img {
								max-width: 73px;
							}
						}
					}
				}
				&:nth-child(2) { }
				&:nth-child(3) {
					.box-icon {
						.circle {
							img {
								top: -3px;
							}
						}
					}
				}
				&:nth-child(4) {
					.box-icon {
						.circle {
							img {
								top: -3px;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 767px) {
			.col-value {
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}
		@media screen and (max-width: 575px) {
			.col-value {
				.box-icon {
					.circle {
						flex: 0 0 125px;
					  max-width: 125px;
					  height: 125px;

						img {
							max-width: 52px;
						}
					}
				}

				.title {
					margin-top: 7px;
					font-size: 1.25rem;
				}
			}
		}
	}

	.about-section {
		padding-top: 55px;
  	padding-bottom: 55px;

		.container {
			.col-image {
				img {
					max-width: 100%;
  				height: auto;
				}
			}

			.col-text {
				color: #3c3c3c;

				.title {
					color: $cprimary;
					font-size: 1.9rem;
  				font-weight: 900;
					text-align: center;
					letter-spacing: 3px;
				}
			}
		}

		@media screen and (max-width: 991px) {
			.container {
				.col-image {
					margin-bottom: 15px;
					text-align: center;
				}
			}
		}
		@media screen and (max-width: 575px) {
			padding-top: 25px;
  		padding-bottom: 25px;

			.container {
				.col-text {
					.title {
						font-size: 1.6rem;
					}

					p {
						line-height: 1.35;
						text-align: justify;
					}
				}
			}
		}
	}

}
