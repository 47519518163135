.btn-s1 {
  padding: 0.55rem 1.1rem 0.55rem 1.2rem;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 0;

  &.bg-cprimary {
    color: #ffffff;
    border-color: $cprimary;
    background-color: $cprimary;

    &:hover, &:focus, &:active {
      border-color: $cprimary2 !important;
      background-color: $cprimary2 !important;
    }
  }

  &.bg-csecondary {
    color: #ffffff;
    border-color: $csecondary;
    background-color: $csecondary;

    &:hover, &:focus, &:active {
      border-color: $csecondary2 !important;
      background-color: $csecondary2 !important;
    }
  }
}
