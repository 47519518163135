#products-results-page{

  .products-section {
    padding-top: 15px;
    padding-bottom: 35px;
    background-color: #f7f7f7;

    .col-title {
      margin-bottom: 5px;
      text-align: center;

      .title-s1 {
        margin-bottom: 4px;
        font-size: 2.0rem;
        font-weight: 900;
        letter-spacing: 3px;
      }

      .info {
        color: $cprimary;
        font-weight: 600;
        line-height: 1.3;
      }
    }

    .col-results {
      >.row {
        justify-content: center;
      }
      @media screen and (min-width: 1200px) {
        padding-left: 35px;
      }
      @media screen and (min-width: 1500px) {
        .box-product-sample-s1 {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
    }

  }

}
