#company-page {

  .basic-info-section {
    position: relative;
    border-bottom: 6px solid #272727;
    background-color: #ffffff;

    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background-position: right center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../images/pages/about-us/image-1.jpg');
    }

    .container {
      >.row {
        min-height: 77vh;
        align-items: center;
      }
    }

    .col-info {
      line-height: 1.3;
      text-align: justify;

      .title {
        font-size: 2.1rem;
        font-weight: 900;
        letter-spacing: 3px;
      }

      .p-lg {
        line-height: 1.4;
        font-size: 1.20rem;
      }
    }

    @media screen and (max-width: 767px) {
      padding-top: 110px;
      padding-bottom: 50px;

      .bg-image {
        top: 0;
        width: 100%;
        height: 100px;
        border-bottom: 2px solid #7b7777;
        background-position: center top;
      }

      .container {
        >.row {
          min-height: inherit;
        }
      }

      .col-info {
        padding-top: 20px;
        padding-bottom: 5px;

        .title {
          text-align: center;
        }
      }
    }
    @media screen and (max-width: 575px) {
      .bg-image {
        background-position: right 43% top;
        background-size: 240%;
      }
    }
  }

  .extra-info-section {
    padding-top: 70px;
    padding-bottom: 70px;

    .row-info {
      align-items: center;
      justify-content: center;

      .col-image {
        .circle {
          position: relative;
          display: inline-block;
          border: 3px solid #9198bc;
          border-radius: 50%;

          img {
            width: 100%;
          }
        }
      }

      .col-text {
        .title {
          color: #6b75a8;
          font-size: 1.9rem;
          font-weight: 900;
          letter-spacing: 5px;
        }

        p {
          color: #414040;
          font-size: 1.20rem;
          font-weight: 600;
          line-height: 1.4;
          letter-spacing: 2px;
        }
      }
    }

    @media screen and (min-width: 1200px) {
      .row-info {
        .col-image {
          flex: 0 0 30%;
          max-width: 30%;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .row-info {
        .col-text {
          margin-top: 10px;
          text-align: center !important;
        }
      }
    }
    @media screen and (max-width: 575px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .our-customers-section {
    background-color: #f6f6f6;
  }

  .certifications-section {
    position: relative;
    padding-top: 45px;
    padding-bottom: 45px;
    background-color: #ffffff;

    .title {
      margin-bottom: 20px;
      color: #000000;
      text-align: center;
      font-size: 2.1rem;
      font-weight: 900;
      letter-spacing: 3px;
      text-align: center;
    }

    .col-image {
      text-align: center;

      img {
        max-width: 100%;
        max-height: 200px;
      }
    }

    @media screen and (max-width: 575px) {
      padding-top: 28px;
      padding-bottom: 28px;

      .col-image {
        &:first-child {
          margin-bottom: 18px;
        }
      }
    }
  }

}
