#contact-page{
  min-height: 68vh;

  .main-section{
    padding-top: 35px;
		padding-bottom: 35px;

    .col-title{
      margin-bottom: 18px;
      text-align: center;

      .title-s1{
        font-size: 2.1rem;
        font-weight: 900;
        text-align: center;
        letter-spacing: 3px;
      }
    }

    .col-info{
      text-align: right;
      line-height: 1.3;

      .title{
        font-size: 1.15rem;
        line-height: 1.1;
      }

      .subtitle{
        color: $csecondary;
        font-size: 1.10rem;
        font-weight: 600;
        line-height: 1.1;
      }

      .btn-network{
        color: #444645;
        font-size: 1.6rem;
      }
    }

    .col-form{
      .btn-s1 {
        width: 220px;
      }
    }

    @media screen and (max-width: 991px){
      .col-title{
        margin-bottom: 0;
      }

      .col-info{
        margin-bottom: 15px;
        text-align: center;
      }
    }
  }

}
