#header{
	position: relative;
	z-index: 20;

	.header-content{
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		// box-shadow: 0 1px 3px rgba(0,0,0,0.1);
		z-index: 30;

		.heade-extra {
			position: relative;
			color: #ffffff;
			background-color: $cprimary;

			.col-info {
				padding-top: 6px;
  			padding-bottom: 7px;
				font-size: 0.80rem;
				font-weight: 600;

				.txt-inf {
					.ic {
						margin-right: 2px;
					}
				}

				a {
					position: relative;
					display: inline-block;
					top: 2px;
					color: #ffffff;

					i {
						font-size: 128%;
					}
				}
			}

			@media screen and (max-width: 991px) {
				padding-top: 7px;
				padding-bottom: 9px;

				.col-info {
					padding-top: 1px;
					padding-bottom: 0;
					text-align: center;
				}
			}
			@media screen and (max-width: 575px) {
				.col-info {
					font-size: 0.70rem;

					.txt-inf {
						display: block;
					}

					a {
						display: none;
					}
				}
			}
		}

		.header-menu {
			position: relative;

			.navbar{
				padding-top: 0.4rem;
				padding-bottom: 0.4rem;
				background-color: #ffffff !important;

				.navbar-brand{
					img{
						max-height: 60px;
					}
				}

				.navbar-toggler{
					padding: 0.30rem 0.8rem;
					color: #ffffff;
					border-radius: 5px;
					border-color: $cprimary;
					background-color: $cprimary;

					i{
						font-size: 1.6rem;
					}
				}

				#nav-collapse{
					.navbar-nav{
						.simple-item{
							.nav-link{
								position: relative;
								color: $cprimary;
								cursor: pointer;
								font-size: 1.15rem;
								font-weight: 700;
								text-transform: uppercase;
								@include transition(200ms);

								&:hover{
									color: $csecondary;
								}

								&.router-link-exact-active{
									color: $cprimary;

									&:after{
										opacity: 1;
										visibility: visible;
									}
								}
							}
						}

						.red-item {
							.nav-link {
								padding-left: 1.1rem;
							  padding-right: 1.1rem;
							  padding-bottom: 0.35rem;
								color: #ffffff !important;
							  border-radius: 5px;
								background-color: $csecondary;

								&:hover {
									background-color: $csecondary2;
								}
							}
						}

						.btn-item{
							.nav-link{
								padding: 0.4rem 1.3rem 0.5rem 1.3rem;
								font-size: 1.0em;
								font-weight: 500;
								text-align: center;
								border: 2px solid transparent;
								border-radius: 26px;
								@include transition(200ms);
							}

							&.bg-cprimary {
								.nav-link {
									color: #ffffff;
									border-color: $cprimary;
									background-color: $cprimary;

									&:hover{
										border-color: $cprimary2;
										background-color: $cprimary2;
									}
								}
							}
							&.outline-csecondary {
								.nav-link {
									color: $csecondary;
									font-weight: 600;
									border-color: $csecondary;
									background-color: transparent;

									&:hover{
										color: $csecondary2;
										border-color: $csecondary2;
									}
								}
							}
							&.demo {
								min-width: 102px;
							}
						}

						.networks-item{
							.nav-link{
								padding: 0.25rem 0.8rem 0.30rem 1.1rem;
								color: #ffffff;
								background-color: $cprimary;
								border-radius: 26px;
								line-height: 1;

								span{
									position: relative;
									top: -4px;
									margin-right: 4px;
									font-size: 0.75rem;
								}

								.btn-network{
									display: inline-block;
									padding: 3px 6px;
									color: #ffffff;
									font-size: 1.3rem;
									@include transition(200ms);

									.fa-apple{
										font-size: 1.6rem;
									}

									&:hover{
										opacity: 0.85;
									}
								}
							}
						}
					}
				}

				@media screen and (min-width: 992px){
					@media screen and (max-width: 1399px){
						.navbar-brand{
							img{
								max-height: 55px;
							}
						}
					}

					@media screen and (max-width: 1199px){
						.navbar-brand{
							img{
								max-height: 50px;
							}
						}

						#nav-collapse{
							.navbar-nav{
								.simple-item{
									margin: 0 1.3vw !important;
								}

								.btn-item{
									margin-left: 10px;
									margin-right: 6px !important;

									.nav-link{
										padding: 0.4rem 0.7rem 0.5rem 0.7rem;
									}
								}

								.networks-item{
									.nav-link{
										padding: 0.25rem 0.4rem 0.3rem 0.8rem;

										span{
											margin-right: 0;
										}
									}
								}
							}
						}
					}

					#nav-collapse{
						.navbar-nav{
							.simple-item{
								margin: 0 1.35vw;
							}

							.btn-item{
								margin-right: 10px;
							}
						}
					}
				}
				@media screen and (min-width: 1300px){
					#nav-collapse{
						.navbar-nav{
							.simple-item{
								margin: 0 1.2vw;
							}

							.btn-item{
								margin-left: 12px;
							}
						}
					}
				}
				@media screen and (max-width: 991px){
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;

					.navbar-brand{
						padding-top: 0.1rem;
						padding-bottom: 0.1rem;

						img{
							max-height: 33px;
						}
					}

					#nav-collapse{
						.navbar-nav{
							.simple-item{
								.nav-link{
									padding: 0.2rem 0;
								}
							}

							.red-item {
								.nav-link {
									text-align: center;
									letter-spacing: 3px;
								}
							}

							.btn-item{
								margin-top: 8px;
								margin-bottom: 10px;
								text-align: center;

								.nav-link{
									font-weight: 700;
								}
							}

							.networks-item{
								text-align: center;

								.nav-link{
									span{
										top: -7px;
									}

									.btn-network{
										margin: 0 4px;
										font-size: 1.5rem;

										.fa-apple{
											font-size: 1.9rem;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// == Products menu ==
	.products-menu{
		position: fixed;
		padding-top: 15px;
		padding-bottom: 15px;
		top: 0;
		left: 0;
		width: 335px;
		height: 100%;
		color: #fff;
		font-size: 15px;
		box-shadow: 1px 0px 2px rgba(0,0,0,0.2);
		background: $cprimary;
		opacity: 0;
		visibility: hidden;
		z-index: 480;
		@include cp-property(transform, translateX(-380px) !important);

		a{
			color: #fff;
		}

		.menu-bg{
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100%;
			background-color: transparent;
			z-index: 1;
		}

		.tablist{
			position: relative;
			width: 100%;
			height: 100%;
			overflow: auto;
			overflow-x: hidden;
			z-index: 2;

			.box-title{
				padding: 8px 0px;
				margin-bottom: 6px;
				font-weight: 400;
				text-align: center;
				cursor: pointer;

				&:hover{
					opacity: 0.8;
				}

				h4{
					font-size: 1.3rem;
				}

				.icon{
					position: absolute;
					top: 13px;
					right: 24px;
					font-size: 1.5rem;
				}
			}
		}

		.card{
			border: 0;
			border-radius: 0;
			background-color: transparent;

			.card-header{
				background-color: transparent;
				border: 0;
				border-radius: 0;
				padding: 0.2rem;

				.btn-toggle{
					position: relative;
					display: block;
					padding: 0px 45px 0px 10px;
					text-align: right;
					color: #fff;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					cursor: pointer;
					@extend .t-250;

					.fake-toggle{
						position: absolute;
						right: 0;
						top: 0;
						width: 43px;
						height: 100%;
						z-index: 10;
					}

					&:after{
						content: " ";
						position: absolute;
						top: 7px;
						right: 18px;
						width: 16px;
						height: 8px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						background-image: url('../images/shared/chevron-down.svg');
						z-index: 1;
					}

					&:hover{
						opacity: 0.8;
					}
				}
				.btn-toggle-fake{
					position: relative;
					display: block;
					padding: 0px 45px 0px 10px;
					text-align: right;
					color: #fff;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					cursor: pointer;
					@extend .t-250;

					.fake-toggle{
						position: absolute;
						right: 0;
						top: 0;
						width: 43px;
						height: 100%;
						z-index: 10;
					}

					&:hover{
						opacity: 0.8;
					}
				}

				.btn-direct-link{
					position: relative;
					right: -43px;
					padding-right: 43px;
				}
			}

			.card-body{
				padding: 0.4rem 0;
				line-height: 1.3;
				background-color: $cprimary2;

				.ul-sub-options{
					padding-left: 0;
					margin-bottom: 0;
					list-style-type: none;

					li{
						margin-top: 5px;
						margin-bottom: 5px;
						display: inline-block;
						width: 100%;
						font-size: 14px;
						text-align: right;

						a{
							display: block;
							padding: 0px 1.45rem;

							&:hover{
								text-decoration: none;
								opacity: 0.8;
							}
						}
					}
				}
			}
		}

		&.active{
			opacity: 1;
			visibility: visible;
			@include cp-property(transform, translateX(0px) !important);
		}
	}
	// == ==

}
